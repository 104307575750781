import React from 'react';
import { User } from '@auth0/auth0-react';
import ReactRoundedImage from 'react-rounded-image';

interface ComponentProps {
  isAuthenticated: boolean
  isLoading: boolean
  user: User | undefined,
}

// eslint-disable-next-line max-len
const Profile = ({
  isAuthenticated, isLoading, user,
}: ComponentProps): React.ReactElement<ComponentProps> | null => {
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!user || !isAuthenticated) {
    return null;
  }

  return (
    (
      <div>
        <a href="/" style={{ display: 'inline-block' }}>
          <ReactRoundedImage
            image={user.picture}
            roundedColor="#66A5CC"
            imageWidth="30"
            imageHeight="30"
            roundedSize="0"
            borderRadius="20"
          />
        </a>
      </div>
    )
  );
};

export default Profile;
