import React, { useState } from 'react';

interface ButtonConfig {
  id: string,
  buttonText: string,
}

interface StatusHash {
  [status: string]: ButtonConfig;
}

const STATUS: StatusHash = {
  IDLE: { id: 'IDLE', buttonText: 'Refresh Cache' },
  RUNNING: { id: 'RUNNING', buttonText: 'Refreshing Cache...' },
  SUCCESS: { id: 'SUCCESS', buttonText: 'Cache Refreshed' },
  FAILURE: { id: 'FAILURE', buttonText: 'Error Refreshing Cache' },
};

const DevTools = (): React.ReactElement => {
  const [refreshCacheStatus, setStatus] = useState(STATUS.IDLE.id);

  const refreshCache = async () => {
    setStatus(STATUS.RUNNING.id);
    const PREFIX = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : '/';
    try {
      await fetch(`${PREFIX}api/do/updateCache`, { // TODO: inject api url
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: new Headers({
          // eslint-disable-next-line quote-props
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }),
      });
      setStatus(STATUS.SUCCESS.id);
    } catch {
      setStatus(STATUS.FAILURE.id);
    }
  };

  return (
    <button type="button" disabled={refreshCacheStatus === STATUS.RUNNING.id} onClick={refreshCache}>{STATUS[refreshCacheStatus].buttonText}</button>
  );
};

export default DevTools;
