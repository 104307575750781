/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import { useQuery, DocumentNode } from '@apollo/client';

import _ from 'lodash';

import { GET_ACTIVITIES, GET_DAYS, GET_MONTHS } from '../graphqlQueries';

import ChartQualityByTimeframe from '../charts/ChartQualityByTimeframe';
import { Activity, Day, Month } from '../../shared/types';

interface Chart {
  id: string,
  text: string,
  query: DocumentNode,
  queryParameter: Object | undefined,
  mapToChartData: Function,
  units?: number,
}

interface ChartHash {
  [chartId: string]: Chart;
}

interface TimePeriod {
  day?: number,
  month?: number,
  year?: number,
  quality: number,
}

interface DayData {
  days: Day[]
}

interface MonthData {
  months: Month[]
}

// eslint-disable-next-line max-len
const daysMapperToChartData = (data: DayData, activeProperty: keyof TimePeriod, days: number) => data.days.slice(-days).map((
  {
    month,
    day,
    [activeProperty]: quality,
  }: TimePeriod,
) => ({ label: `${month}-${day}`, quality }));

// eslint-disable-next-line max-len
const monthsMapperToChartData = (data: MonthData, activeProperty: keyof TimePeriod) => data.months.map((
  {
    month,
    year,
    [activeProperty]: quality,
  }: TimePeriod,
) => ({ label: `${month}/${year}`, quality }));

const CHARTS: ChartHash = {
  WEEK: {
    id: 'WEEK', text: 'week', query: GET_DAYS, queryParameter: undefined, mapToChartData: daysMapperToChartData, units: 7,
  },
  MONTH: {
    id: 'MONTH', text: 'month', query: GET_DAYS, queryParameter: 1, mapToChartData: daysMapperToChartData, units: 30,
  },
  YEAR: {
    id: 'YEAR', text: 'year', query: GET_MONTHS, queryParameter: undefined, mapToChartData: monthsMapperToChartData, units: 12,
  },
};

interface ComponentProps {
  chartId: string
  property: keyof TimePeriod
  title: string,
}

const QualityChart = (
  { chartId, property, title }: ComponentProps,
): React.ReactElement<ComponentProps> => {
  // it appears you can't pass different queries anymore to this function...
  // it just keeps populating the first one, even if you refresh. ugh
  // see https://www.apollographql.com/docs/react/data/queries/
  // what a waste of time!

  const yearResults = useQuery(GET_MONTHS, {});

  const dayResults = useQuery(GET_DAYS, {});
  const data = (chartId === 'YEAR') ? yearResults.data : dayResults.data;
  const loading = (chartId === 'YEAR') ? yearResults.loading : dayResults.loading;
  const error = (chartId === 'YEAR') ? yearResults.error : dayResults.error;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (!data) return <p>No data found</p>;

  const chartData = CHARTS[chartId].mapToChartData(data, property, CHARTS[chartId].units);

  return (
    <div>
      <ChartQualityByTimeframe data={chartData} title={title} />
    </div>
  );
};

interface ActivitiesData {
  activities: Activity[];
}

const QUALITY_ENTITY = {
  activity: 'quality', plural: 'quality', gospel: 'none', how: 'none',
};

const DaysChart = (): React.ReactElement => {
  const [activeChart, setActiveChart] = useState(CHARTS.WEEK.id);
  const [activeProperty, setActiveProperty] = useState(QUALITY_ENTITY.activity);

  const { loading, error, data } = useQuery<ActivitiesData>(GET_ACTIVITIES);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (!data) return <p>No data found</p>;

  const { activities } = data;

  const [...properties] = activities;

  properties.unshift(QUALITY_ENTITY);

  const propertiesButtons = _.map(properties, (activity) => <button type="button" key={activity.activity} disabled={activeProperty === activity.plural.replace(/ /g, '_').toLowerCase()} onClick={() => setActiveProperty(activity.plural.replace(/ /g, '_').toLowerCase())}>{_.startCase(activity.activity)}</button>);

  const theActivePropertyObject = _.find(properties, (property) => property.plural.replace(/ /g, '_').toLowerCase() === activeProperty);
  const title = theActivePropertyObject?.activity;

  return (
    <>
      <div>
        <button type="button" disabled={activeChart === CHARTS.WEEK.id} onClick={() => setActiveChart(CHARTS.WEEK.id)}>Week</button>
        <button type="button" disabled={activeChart === CHARTS.MONTH.id} onClick={() => setActiveChart(CHARTS.MONTH.id)}>Month</button>
        <button type="button" disabled={activeChart === CHARTS.YEAR.id} onClick={() => setActiveChart(CHARTS.YEAR.id)}>Year</button>
      </div>
      <div>
        {propertiesButtons}
      </div>
      <div>
        <QualityChart
          chartId={activeChart}
          property={activeProperty as keyof TimePeriod}
          title={title || ''}
        />
      </div>
    </>
  );
};

export default DaysChart;
