import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { sunrise } from 'meeussunmoon';

import './css/normalize.css';
import './css/style.css';
import './css/muffin-man.css';

import {
  useQuery,
} from '@apollo/client';

import { DateTime } from 'luxon';
import { getHumanTimeOfDay, getHumanMeteorologicalTimeOfYear } from './days';

import { GET_MONTHS } from './graphqlQueries';
import DaysChart from './mainChart/DaysChart';
import DevTools from './devTools/DevTools';

function QualityTable() {
  const { loading, error, data } = useQuery(GET_MONTHS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  interface TimePeriod {
    month: number,
    year: number,
    quality: number,
  }

  return data.months.map(({ month, year, quality }: TimePeriod) => (
    <div key={`${month}-${year}`}>
      <p>
        {month}
        /
        {year}
        :
        {' '}
        {quality}
      </p>

    </div>
  ));
}

const Home = (): React.ReactElement | null => {
  const [canAccess, setAccessAllowed] = useState<boolean | null>(null);
  const {
    isAuthenticated, isLoading,
  } = useAuth0();
  useEffect(() => {
    // TODO: use useEffect correctly!
    if (localStorage.getItem('token') && canAccess === null && !isLoading && isAuthenticated) {
      const PREFIX = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : '/';
      fetch(`${PREFIX}api/isAllowed`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: new Headers({
          // eslint-disable-next-line quote-props
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }),
      }).then((results) => {
        if (results.status === 200) {
          setAccessAllowed(true);
        } else {
          setAccessAllowed(false);
        }
      }).catch(() => setAccessAllowed(false));
    }
  });

  if (canAccess === null) {
    return <div>Loading...</div>;
  }

  if (!canAccess) {
    return null;
  }

  return (
    <div className="App">
      <h1 className="center">
        {getHumanTimeOfDay(new Date())}
      </h1>
      <h2 className="center">
        {getHumanMeteorologicalTimeOfYear(new Date())}
      </h2>
      <DaysChart />
      <QualityTable />
      <p>{sunrise(DateTime.now(), 45, -122.242670).toLocaleString()}</p>
      <div>
        <img
          className="cover-image"
          src="https://res.cloudinary.com/calm-com/image/upload/v1582139698/jasper-lake.jpg"
          data-src="https://res.cloudinary.com/calm-com/image/upload/v1582139698/jasper-lake.jpg"
          data-srcset="https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_300,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 300w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_400,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 400w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_768,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 768w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_1024,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 1024w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_1440,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 1440w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_1920,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 1920w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_2560,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 2560w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_3840,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 3840w"
          alt="Jasper Lake"
          srcSet="https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_300,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 300w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_400,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 400w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_768,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 768w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_1024,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 1024w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_1440,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 1440w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_1920,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 1920w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_2560,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 2560w, https://res.cloudinary.com/calm-com/image/upload/f_auto,c_scale,w_3840,dpr_auto,q_auto/v1582139698/jasper-lake.jpg 3840w"
          data-loaded="true"
        />
      </div>
      <DevTools />
      <div />
    </div>
  );
};

export default Home;
