enum HumanTimesOfDay {
  Morning = 'Morning',
  Afternoon = 'Afternoon',
  Evening = 'Evening',
  Night = 'Night',
}

export const getHumanTimeOfDay = (date: Date): HumanTimesOfDay => {
  const hour = date.getHours();
  if (hour < 4) {
    return HumanTimesOfDay.Night;
  } if (hour < 12) {
    return HumanTimesOfDay.Morning;
  } if (hour < 17) {
    return HumanTimesOfDay.Afternoon;
  } if (hour < 21) {
    return HumanTimesOfDay.Evening;
  }
  return HumanTimesOfDay.Night;
};

enum HumanTimesOfYear {
  EarlyWinter = 'Early Winter',
  MidWinter = 'Mid Winter',
  LateWinter = 'Late Winter',
  EarlySpring = 'Early Spring',
  MidSpring = 'Mid Spring',
  LateSpring = 'Late Spring',
  EarlySummer = 'Early Summer',
  MidSummer = 'Mid Summer',
  LateSummer = 'Late Summer',
  EarlyFall = 'Early Fall',
  MidFall = 'Mid Fall',
  LateFall = 'Late Fall',
}

const HumanMeteorologicalTimesOfYear: Record<number, HumanTimesOfYear> = {
  1: HumanTimesOfYear.MidWinter,
  2: HumanTimesOfYear.LateWinter,
  3: HumanTimesOfYear.EarlySpring,
  4: HumanTimesOfYear.MidSpring,
  5: HumanTimesOfYear.LateSpring,
  6: HumanTimesOfYear.EarlySummer,
  7: HumanTimesOfYear.MidSummer,
  8: HumanTimesOfYear.LateSummer,
  9: HumanTimesOfYear.EarlyFall,
  10: HumanTimesOfYear.MidFall,
  11: HumanTimesOfYear.LateFall,
  12: HumanTimesOfYear.EarlyWinter,
};

// eslint-disable-next-line max-len
export const getHumanMeteorologicalTimeOfYear = (date: Date): HumanTimesOfYear => HumanMeteorologicalTimesOfYear[date.getMonth() + 1];
