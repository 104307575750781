/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_MONTHS = gql`
query GetMonths {
  months {
    month
    year
    quality
    workouts
    days_meditating
    exuberent_moments
    big_ideas
    conversations
    annoyed_days
    poor_sleep_days
    poor_eating_days
    sick_days
    wines
    days_writing
    days_hacking
    opportunities_explored
    days_reading
    public_participations
    travel_days
  } 
}
`;

export const GET_DAYS = gql`
query GetDays($daysTake: Int) {
  days (take: $daysTake) {
    date
    quality
    day
    month
    year
    workouts
    days_meditating
    exuberent_moments
    big_ideas
    conversations
    annoyed_days
    poor_sleep_days
    poor_eating_days
    sick_days
    wines
    days_writing
    days_hacking
    opportunities_explored
    days_reading
    public_participations
    travel_days
  }
}
`;

export const GET_ACTIVITIES = gql`
query GetActivities {
  activities {
    activity
    gospel
    how
    plural
  }
}
`;
