import React from 'react';
import { useAuth0, User } from '@auth0/auth0-react';

interface ComponentProps {
  isAuthenticated: boolean
  isLoading: boolean
  user: User | undefined,
}

// eslint-disable-next-line max-len
const LogoutButton = ({ isAuthenticated, isLoading, user }: ComponentProps): React.ReactElement<ComponentProps> | null => {
  const { logout } = useAuth0();
  const logUserOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    logout({ returnTo: window.location.origin });
  };

  if (isLoading) {
    return <>...</>;
  }

  if (!isAuthenticated || !user) {
    return null;
  }

  return (
    <div>
      <button type="button" onClick={() => logUserOut()}>
        Log Out
      </button>
    </div>
  );
};

export default LogoutButton;
