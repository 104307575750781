import React from 'react';
import { Chart } from 'react-google-charts';

interface TimeSeries {
  label: string,
  quality: number,
}

interface ComponentProps {
  data: Array<TimeSeries>,
  title: string,
}

const Component: React.FC<ComponentProps> = ({ data, title }: ComponentProps) => {
  const chartData: Array<Array<string | number | unknown | null>> = data.map((point) => [point.label, point.quality, 'green', null]);
  const header = [
    'Element',
    'Quality',
    { role: 'style' },
    {
      sourceColumn: 0,
      role: 'annotation',
      type: 'string',
      calc: 'stringify',
    },
  ];
  chartData.unshift(header);
  return (
    <Chart
      width="100%"
      height="400px"
      chartType="ColumnChart"
      loader={<div>Loading Chart</div>}
      data={chartData}
      options={{
        title,
        bar: { groupWidth: '95%' },
        legend: { position: 'none' },
      }}
      // For tests
      rootProps={{ 'data-testid': '1' }}
    />
  );
};

export default Component;
