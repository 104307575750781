import React from 'react';
import { useAuth0, User } from '@auth0/auth0-react';

interface ComponentProps {
  isAuthenticated: boolean
  isLoading: boolean
  user: User | undefined,
}

// eslint-disable-next-line max-len
const LoginButton = ({ isAuthenticated, isLoading, user }: ComponentProps): React.ReactElement<ComponentProps> | null => {
  const { loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <>...</>;
  }
  if (isAuthenticated && user) {
    return null;
  }
  return <div><button type="button" onClick={() => loginWithRedirect()}>Log In</button></div>;
};

export default LoginButton;
