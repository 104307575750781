import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import LoginButton from './auth/LoginButton';
import LogoutButton from './auth/LogoutButton';
import Profile from './auth/ProfileButton';

import Header from './nav/Header';

import Home from './Home';

import type { UserData } from '../shared/types';

// TODO: wrap these components with a context provider that stores profile info or use apollo
// https://nextsteps.dev/apollo-client-graphQL-and-auth
// or https://www.apollographql.com/docs/react/v2/data/local-state/

const component = (): React.ReactElement => {
  const {
    user, isAuthenticated, isLoading,
  } = useAuth0();
  const {
    getAccessTokenSilently,
  } = useAuth0();
  const [userMetadata, setUserMetadata] = useState<UserData>(null);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = 'joropeza.us.auth0.com';

      try {
        if (user) {
          const accessToken = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: 'read:current_user',
          });

          const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user?.sub}`;

          const metadataResponse = await fetch(userDetailsByIdUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const { user_metadata: fetchedUserMetadata } = await metadataResponse.json();

          setUserMetadata(fetchedUserMetadata);
          localStorage.setItem('token', fetchedUserMetadata.token);
          localStorage.setItem('user', JSON.stringify(user));

          // eslint-disable-next-line no-console
          console.log('profile load success');
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.log('profile load failure: ', e?.message, 'clearing existing session');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  return (
    <>
      <header>
        <div className="app-logo">Better Days</div>
        <nav className="app-user">
          <LoginButton user={user} isAuthenticated={isAuthenticated} isLoading={isLoading} />
          <LogoutButton user={user} isAuthenticated={isAuthenticated} isLoading={isLoading} />
          <Profile
            user={user}
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
        </nav>
      </header>
      <Home />
    </>
  );
};

export default component;
